.import-file-list {
  & .ReactVirtualized__Table__headerRow {
    @apply border-b border-grey-5 pr-0 normal-case;
  }

  & .ReactVirtualized__Table__headerColumn:first-of-type,
  & .ReactVirtualized__Table__rowColumn:first-of-type {
    @apply pl-0;
  }

  & .ReactVirtualized__Table__row:hover {
    @apply bg-grey-3;
  }

  & .ReactVirtualized__Table__headerColumn {
    &:last-child {
      @apply pr-4;
    }
  }

  & .ReactVirtualized__Table__headerColumn,
  & .ReactVirtualized__Table__rowColumn {
    @apply mr-0 box-border flex;

    &:first-child {
      @apply pl-4;
    }

    &:last-child {
      @apply pr-4;
    }
  }
}
