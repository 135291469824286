@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-height: 100vh;
}

@layer base {
  a {
    @apply break-words text-blue-9 no-underline;

    &:hover {
      @apply underline;
    }
  }

  b,
  strong {
    @apply font-semibold;
  }

  /**
   * Hides the search input clear button for search inputs
   */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .shimmer-background {
    @apply animate-shimmer bg-grey-5 bg-[url('/assets/gradient.png')] bg-[length:400%_400%];
    animation: shimmer 2s infinite cubic-bezier(0.14, 0.38, 0.89, 0.65);

    @keyframes shimmer {
      0% {
        background-position: 100% 100%;
      }
      100% {
        background-position: 0 0;
      }
    }
  }

  .dragging {
    cursor: grabbing;
  }
}

html {
  @apply h-full;
  -webkit-overflow-scrolling: touch;
}

body {
  @apply h-[inherit] bg-grey-1 text-[1em] leading-[1.5] antialiased;

  font-family:
    system-ui,
    -apple-system,
    Segoe UI,
    Roboto,
    Ubuntu,
    Cantarell,
    Noto Sans,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

#__next {
  @apply relative z-0 h-[inherit];
  /*
    This is needed to solve a weird iOS webkit bug where if z-index is present,
    it'll sometimes prevent an element from scrolling. This is obviously very
    important for this component.
    https://stackoverflow.com/questions/26176288/webkit-overflow-scrolling-touch-breaks-in-apples-ios8/26988408#26988408
    https://css-tricks.com/forums/topic/safari-for-ios-z-index-ordering-bug-while-scrolling-a-page-with-a-fixed-element/
  */
  -webkit-transform: translate3d(0, 0, 0);
}

code {
  @apply inline-block;
  @apply bg-pigeon-50;
  @apply px-2;
  @apply rounded;
  @apply font-mono;
  @apply font-medium;
  @apply text-pigeon-600;
  font-size: inherit;
}

* {
  min-width: 0;
  box-sizing: border-box;
}

.versions-list-container {
  &::-webkit-scrollbar {
    @apply h-1;
  }

  &::-webkit-scrollbar-track-piece {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-pigeon-200;
    @apply rounded-3xl;

    &:hover {
      @apply bg-pigeon-50;
    }
  }

  & > .version-list-item {
    @apply mr-4;

    &:first-of-type {
      @apply mr-8;
    }

    &:last-of-type {
      @apply mr-0 #{!important};
    }
  }
}

@keyframes new-shimmer {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.cypress {
  * {
    animation-duration: 0s !important;
    transition: none !important;
  }
}

.disable-hover * {
  @apply pointer-events-none;

  #scrollContainer {
    pointer-events: all;
  }
}

/**
  * Explanation of why the below iOS specific stuff exists:
  * NOTE: this was found in iOS13
  * Safari triggers resize events on scroll and touch events.
  * We use a resize listener on the container which would get marked as sticky.
  * This leads to the container's height constantly being recalculated.
  * This means there are staggered and brief moments of height being marked as 0.
  * Those moments may be left unrectified by a misqueueing of the calculations until another event fixes it.
  * This leads to the disappearance of the element on touch and the flickering of it on scroll.

  * 1) The the GPU acceleration prevents the flickering on scroll for fixed/sticky elements.
  * 2) The switch to fixed ensures we dont care about the recalculating container for touch events on this element or it's children.
*/
@supports (-webkit-touch-callout: none) {
  .ios-safari-fixed-element-hack {
    position: fixed;
    width: 100vw;
  }
}

.ios-safari-fixed-element-hack {
  -webkit-transform: translate3d(0, 0, 0);
}

label.auto-width {
  @apply relative m-0.5 box-border inline-grid min-w-0 items-center overflow-hidden align-top;

  &::after {
    @apply pointer-events-none invisible box-border flex w-auto resize-none appearance-none whitespace-pre-wrap rounded border-none bg-none font-medium text-grey-11;
    content: attr(data-value) ' ';
    grid-area: 1 / 2;

    &:focus-within {
      input {
        outline: none;
      }
    }
  }

  & > input {
    @apply box-border flex w-auto min-w-0 resize-none appearance-none;
    grid-area: 1 / 2;
  }
}

.mentions-input {
  &__control {
    @apply relative;
  }

  &__highlighter {
    @apply border border-transparent px-3 pt-3;
  }

  &__input {
    @apply box-border block w-full resize-none rounded border border-transparent px-3 pt-3;

    &:focus,
    &:focus-visible {
      @apply border-transparent outline-none;
    }
  }
}

.ReactVirtualized__Grid:focus,
.ReactVirtualized__Grid__innerScrollContainer:focus,
.ReactVirtualized__Grid[data-focus-visible-added],
.ReactVirtualized__Grid__innerScrollContainer[data-focus-visible-added] {
  outline: none !important; /*style for .js-focus-visible in line 45 is very detailed with all its :not and overrides this */
}
