.StripeElement {
  @apply border;
  @apply border-grey-5;
  @apply rounded;
  @apply p-4;
  @apply h-12;
  @apply bg-grey-1;
  @apply font-[inherit];
  @apply text-grey-12;

  &:hover,
  &--hover {
    @apply border-grey-8;
  }

  &--focus,
  &--focus:hover {
    @apply border-grey-12;
  }

  &--invalid,
  &--invalid:hover {
    @apply border-red-10;
  }

  &--invalid + div {
    @apply block;
  }
}
