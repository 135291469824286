.table-page-container {
  .ReactVirtualized__Grid.ReactVirtualized__List {
    @apply flex-1;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    @apply m-auto !overflow-x-auto;

    /* Hide scrollbar for Chrome, Safari, and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* change height of empty state to not interfere with sticky header*/
  section {
    @apply h-auto;
  }
}
